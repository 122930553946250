<template>
  <div class="shadow p-5 mt-5 mb-5 text-center">
    <h5>Example component</h5>
    <p>This a is test component for developer.</p>
  </div>
</template>
<script>
export default {
  data() {},
};
</script>
